import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export class Bathroom extends Component {
    render() {
        //Start ItemTwo Loop
        const itemtwodata = this.props.itemtwosData.map((itemtwo, index) => (
            
            <div className={itemtwo.className} key={index}>
                <div className={itemtwo.FeaturedClass}>
                    <span className="featured">{itemtwo.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemtwo.planName}</h3>
                    <span className="value">{itemtwo.price}</span>
                    <span className="period">{itemtwo.description}</span>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemtwo.content1}</li>
                        <li>{itemtwo.content2}</li>
                        <li>{itemtwo.content3}</li>
                        <li>{itemtwo.content4}</li>
                        <li>{itemtwo.content5}</li>
                        <li>{itemtwo.content6}</li>
                        <li>{itemtwo.content7}</li>
                        <li>{itemtwo.content8}</li>
                    </ul>
                    <div className="center-wrap">
                        <Link to={itemtwo.btnlink} className="btn-a">
                            <div className="button">
                                {itemtwo.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div>
                </div>
            </div>
            
        ));
        //End ItemTwo Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemtwodata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
Bathroom.PropsTypes = {
    itemtwosData: PropTypes.array
};
Bathroom.defaultProps = {
    itemtwosData: [
        {
            planName: "BASIC (20 Sf)",
            className: "plan",
            description: "A new look for the bathroom",
            FeaturedClass: "",
            Featured: "",
            price: "$5,000",
            content1: "Countertops: Porcelain, Laminate, Granite, Marble, Quartz.",
            content2: "Flooring choices are: Vinyl tile, Ceramic Tile, Porcelain Tile, Natural Stone Tile, Engineered Wood.",
            content3: "Sink",
            content4: "Toilet",
            content5: "Mirror",
            content6: "Paint",
            content7: "Single vanity",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        {
            planName: "Mid-Range (40 Sf)",
            className: "plan featured",
            description: "Best bang for your bucks.",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
            price: "$11,000",
            content1: "Countertops: Porcelain, Laminate, Granite, Marble, Quartz.",
            content2: "Flooring choices are: Vinyl tile, Ceramic Tile, Porcelain Tile, Natural Stone Tile, Engineered Wood.",
            content3: "Shower",
            content4: "Sink",
            content5: "Toilet",
            content6: "Mirror",
            content7: "Paint",
            content8: "Double vanity",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        {
            planName: "Professional (80 Sf)",
            className: "plan",
            description: "A completely elegant and class new look for your bathroom",
            FeaturedClass: "",
            Featured: "",
            price: "$22,000",
            content1: "Countertops: Porcelain, Laminate, Granite, Marble, Quartz.",
            content2: "Flooring choices are: Vinyl tile, Ceramic Tile, Porcelain Tile, Natural Stone Tile, Engineered Wood.",
            content3: "Miscellaneous hardware (knobs, shower rods, towel bars and more) ",
            content4: "Vanity sinks",
            content5: "Countertops",
            content6: "Light fixtures and Doors",
            content7: "Sink, Toilet, Mirror, Paint",
            content8: "Double vanity",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        
    ]
};
export default Bathroom;
