import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export class Kitchen extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (
            
            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                    <span className="value">{itemone.price}</span>
                    <span className="period">{itemone.description}</span>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemone.content1}</li>
                        <li>{itemone.content2}</li>
                        <li>{itemone.content3}</li>
                        <li>{itemone.content4}</li>
                        <li>{itemone.content5}</li>
                        <li>{itemone.content6}</li>
                        <li>{itemone.content7}</li>
                        <li>{itemone.content8}</li>
                    </ul>
                    <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div>
                </div>
            </div>
            
        ));
        //End ItemOne Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
Kitchen.PropsTypes = {
    itemonesData: PropTypes.array
};
Kitchen.defaultProps = {
    itemonesData: [
        {
            planName: "BASIC",
            className: "plan",
            description: "Include 7 features for your kitchen",
            FeaturedClass: "",
            Featured: "",
            price: "$15,000",
            content1: "Refinish Cabinets",
            content2: "Sink Upgrade",
            content3: "Faucet Upgrade",
            content4: "Replace Countertop (Laminate, Vinyl, Tile)",
            content5: "Painting Walls and Ceiling",
            content6: "Appliance Upgrade",
            content7: "Installing Led",
            BtnName: "Purchase Now",
            btnlink: "/#0 ",
        },
        {
            planName: "Mid-Range",
            className: "plan featured",
            description: "Best bang for your bucks.",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
            price: "$40,000",
            content1: "Installing Semi Custom Cabinets",
            content2: "Installing New Sink and Faucet",
            content3: "Replaceing Countertops (Stone, Wood, Metal)",
            content4: "Replace Floor (Tile, Laminte, Stone)",
            content5: "Installing High End Appliances",
            content6: "Adding Backsplash",
            content7: "Installing Led",
            content8: "Appliances Upgrade",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        {
            planName: "Professional",
            className: "plan",
            description: "A new high end quality kitchen.",
            FeaturedClass: "",
            Featured: "",
            price: "$60,000",
            content1: "General Contractor",
            content2: "Custom Cabinets Built",
            content3: "Installing Custom Cabinets",
            content4: "Higher Quality Countertops (Granite, Quartz)",
            content5: "Adding Overhead Lighting",
            content6: "Adding Hardwood Flooring",
            content7: "Installing New Sink, Faucet, and High End Appliances",
            content8: "Stuctural Changes (Knocking Down Walls, Installing Pipes, Wires)",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        
    ]
};
export default Kitchen;
