import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import { Link } from 'react-router-dom';

export class Miscellaneous extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (
            
            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemone.content1}</li>
                        <li>{itemone.content2}</li>
                        <li>{itemone.content3}</li>
                        <li>{itemone.content4}</li>
                        <li>{itemone.content5}</li>
                        <li>{itemone.content6}</li>
                        <li>{itemone.content7}</li>
                        <li>{itemone.content8}</li>
                    </ul>
                    <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div>
                </div>
            </div>
            
        ));
        //End ItemOne Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
Miscellaneous.PropsTypes = {
    itemonesData: PropTypes.array
};
Miscellaneous.defaultProps = {
    itemonesData: [
        {
            planName: "FLOORING",
            className: "plan",
            description: "test",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "Brick Pavers Installation Cost: $12.00 Per Square Foot",
            content2: "Backer Board Installation Cost: $8.00 Per Square Foot",
            content3: "Marble Floor Installation Cost: $35.00 Per Square Foot",
            content4: "Flooring Installation Cost For 12 x 12 Ceramic Tile: $20.00",
            content5: "Engineered Wood Floor Installation Cost: $15.00 Per Square Foot",
            content6: "Laminate Flooring Installation Cost: $15.00 Per Square Foot",
            content7: "Vinyl Tile Flooring Installation Cost: $15.00",
            content8: "And more...",
            BtnName: "Purchase Now",
            btnlink: "/#0 ",
        },
        {
            planName: "PLUMBING",
            className: "plan",
            description: "test",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "Electric Water Heater Installation Cost: $800.00",
            content2: "Steam Shower Installation Cost: $8500.00",
            content3: "Tank Heater Installation Cost: $700.00",
            content4: "Tankless Electric Water Heater Intallation Cost: $800.00",
            content5: "Water Softener Installation Cost: : $800.00",
            content6: "Bathtub Installations Cost:$1,500",
            content7: "Custom Shower Plumbing Cost: $3,000",
            content8: "And more...",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        {
            planName: "FRAMING",
            className: "plan",
            description: "test",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "$95.00 Per Square Foot for finished home framing",
            content2: "Carpentry Work: $65.00 Per Square Foot for unfinished home framing",
            content3: "Frame Interior Non Load Bearing Wall Installation Cost: $50.00",
            content4: "Baseboard installation: $4 Per Linear Foot",
            content5: "Mid-grade trim: $8.00 Per Linear Foot",
            content6: "Crown molding: $12.00 Per Linear Foot",
            content7: "Exterior Trim Installation: $ 9.00 Per Linear Foot (Wood Trim)",
            content8: "And more...",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
        {
            planName: "Other Services",
            className: "plan",
            description: "test",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "Epoxy",
            content2: "Painting",
            content3: "Electrical",
            content4: "Masonry",
            content5: "Insulation",
            content6: "Drywall",
            content7: "Roofing",
            content8: "And more...",
            BtnName: "Purchase Now",
            btnlink: "/#0",
        },
    ]
};
export default Miscellaneous;
