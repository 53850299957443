import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import ScrollAnimation from 'react-animate-on-scroll';

class About extends Component {
    render() {
        //About loop start
        const aboutdata = this.props.aboutsData.map((about, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
                <div className="about-info">
                    <Icofont icon={about.Icon} />
                    <h3>{about.title}</h3>
                    <p>{about.content}</p>
                </div>
            </div>
        ));
        //About loop END
        return (
            <React.Fragment>
                <section id="about" className="about-us ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    
                    <div className="row">
                        {aboutdata}
                    </div>
                </div>
            </section>   
            </React.Fragment>
        );
    }
}
//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    aboutsData: PropTypes.array
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "About",
    sectionTitle: "About Us",
    sectionDescription:
        "Impact Builders has been remodeling and building for over 20 years. We incorporated nearly 10 years ago. Like any impactful business, Impact Builders has a vision for its future, and we are dedicated to our mission. Take a minute to read our mission and vision statements. We believe that when you have a mission and vision statement, our clients know that we are focused and ready to complete their project.",
        aboutsData: [
        {
            Icon: "icofont-binoculars",
            title: "Backgrounds",
            content: "Welcome to Impact Builders LLC, the premier home remodeling contractor for the Phoenix, Arizona area (ROC# 298594).",
        },
        {
            Icon: "icofont-direction-sign",
            title: "Our Approach",
            content: "Impact Builders LLC is the Phoenix home remodeling Contractor of choice known for their \"no pressure\" approach. Impact Remodeling is known for our artisan craftsmanship, attention to detail, and professional work that is fully licensed, bonded, and insured for general contracting in the State of Arizona"
        },
        {
            Icon: "icofont-thermometer",
            title: "Methodology",
            content: "Our goal as experienced commercial renovation contractors is to take your ideas, needs, and wants and make them a reality. While we will certainly make recommendations, in the end, it’s your project. You’re in control every step of the way.",
        },
        {
            Icon: "icofont-users-social",
            title: "Customer Dedication",
            content: "Our dedication to customer satisfaction and craftsmanship means we can deliver incredible features and designs at fair prices. We make sure that the project gets the attention and care it deserves.",
        },
        {
            Icon: "icofont-money",
            title: "Value for Money",
            content: "We also ensure our clients get the best quality in terms of products. You are assured of top-rated brands and exclusive warranties.",
        },
        {
            Icon: "icofont-unique-idea",
            title: "Attentive",
            content: "No matter what your unique needs may be, we will do what we can to work with them. Just tell us how we can customize your renovation project and we will do what we can!",
        },
    ]
};

export default About;
