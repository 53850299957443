import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import { Accordion, AccordionItem } from "react-sanfona";
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import PDF from '../assets/Pricing.pdf'

class FAQ extends Component {
    goToContact() {
        var element = document.getElementById("contact");
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    gotoPricing() {
        const msg = "Our comprehensive ";
        const link = <a href = {PDF} target="_blank">pricing list</a>
    
        // Composing them
        return <div>{msg}{link}</div>;
    }
    render() {
        return (
            <React.Fragment>
                <section className="faq ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="faq-content">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div onClick={this.goToContact}>
                                        <div className="content-box color-effect-1">
                                            <h3>{this.props.ContentTitle}</h3>

                                            <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                                                <div className="box-icon"><Icofont icon="icofont-share-alt" /></div>
                                            </div>

                                            <p>{this.props.ContentDescription}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8">
                                    <Accordion
                                        rootTag="div"
                                        className="panel-group"
                                    >
                                        {this.props.faqData.map(item => {
                                            return (
                                                <AccordionItem
                                                    key={item}
                                                    title={item.title}
                                                    expanded={true}
                                                    expandedClassName=""
                                                    className="panel-title panel panel-default"
                                                    titleTag="a"
                                                    titleClassName=""
                                                >
                                                    <div>
                                                        <div className="panel-body">
                                                            <p>{item.description}</p>
                                                            
                                                        </div>
                                                    </div>
                                                </AccordionItem>
                                            );
                                        })}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
FAQ.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    ContentLink: PropTypes.string,
    ContentTitle: PropTypes.string,
    ContentDescription: PropTypes.string,
    faqData: PropTypes.array
};

//Default Props
FAQ.defaultProps = {
    SectionbgTitle: "FAQ",
    sectionTitle: "Frequently Ask & Questions",
    sectionDescription:
        "We understand that you might have a lot of questions regarding roofing, construction, masonry, painting, and framing. To answer some of your questions, enjoy our FAQ section.",
    
    ContentTitle: "One More Question?",
    ContentDescription: "If you have more questions, send us a message and we will answer you as soon as possible.",
    ContentLink: "/#0",

        faqData: [
        {
            title: "How to view our pricing?",
            description: <>Our comprehensive pricing list<a href={PDF} target="_blank">pricing.pdf</a></>,
        },
        {
            title: "What is Item Support?",
            description: "Bathroom, kitchen, epoxy, painting, flooring, concrete, masonry, framing, insulations, drywall, roofing, plumbing, and appliances",
        },
        // {
        //     title: "How to contact an author?",
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nisl lorem, dictum id pellentesque at, vestibulum ut arcu. Curabitur erat libero, egestas eu tincidunt ac, rutrum ac justo. Vivamus condimentum laoreet lectus, blandit posuere tortor aliquam vitae. Curabitur molestie eros.",
        // }
    ]
};

export default FAQ;
