import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "At Impact Builders LLC, we focus a lot of our energy into making sure that our clients get the best value for money they can. We listen and are responsive to your needs. Call us for more information!",

    servicesData: [
        {
            icon: "icofont-gears",
            heading: "Services",
            description:
                "At Impact Builders LLC, we focus a lot of our energy into making sure that our clients get the best value for money they can. We listen and are responsive to your needs. Call us for more information!"
        },
        {
            icon: "icofont-businessman",
            heading: "Team of Professionals",
            description:
                "We work with a team of professionals who have been in the industry for years to help you get the most value overall. They also have a myriad of skillsets."
        },
        {
            icon: "icofont-safety",
            heading: "Safe Practices",
            description:
                "Choosing to work with us means that you don’t have to worry about your project anymore. It’s in the safest hands in Phoenix."
        },
        {
            icon: "icofont-woman-in-glasses",
            heading: "Customer Dedication",
            description:
                "Our dedication to customer satisfaction and craftsmanship means we can deliver incredible features and designs at fair prices. We make sure that the project gets the attention and care it deserves."
        },
        {
            icon: "icofont-speech-comments",
            heading: "Regular Communication",
            description:
                "We believe in consulting with you throughout the project so that the result is something you’ll love. Our communication will ensure you know what stage of your construction project we’re at."
        },
        {
            icon: "icofont-laptop-alt",
            heading: "Top Quality",
            description:
                "We also ensure our clients get the best quality in terms of products. You are assured of top-rated brands and exclusive warranties."
        },
    ]
};

export default Services;
