import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';

import Kitchen from "./pricingItem/Kitchen";
import Bathroom from "./pricingItem/Bathroom";
import Miscellaneous from './pricingItem/Miscellaneous';

export class Pricing extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="pricing" className="our-pricing ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tab-slider">
                                    <Tabs defaultActiveKey="kitchen" id="uncontrolled-tab">
                                        <Tab eventKey="kitchen" title="Kitchen">
                                            {/* ItemOne Component from src/components/pricingItem/ItemOne */}
                                            <Kitchen />
                                        </Tab>
                                        <Tab eventKey="bathroom" title="Bathroom">
                                            {/* ItemOne Component from src/components/pricingItem/ItemTwo */}
                                            <Bathroom />
                                        </Tab>
                                        <Tab eventKey="miscellaneous" title="Miscellaneous">
                                            <Miscellaneous />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
//Props Types
Pricing.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Pricing.defaultProps = {
    SectionbgTitle: "Pricing",
    sectionTitle: "Pricing",
    sectionDescription:
        "We provide over the phone and on site consultation.  We provide recommendations and including written estimates.",

};
export default Pricing
